
import './NotFoundPage.css';

export const NotFoundPage = () => {
  return (
    <main>
      <div className='not-found'>
        <span>Looks like the content you are looking for does not exist 😕</span>
      </div>
    </main>

  );
}
